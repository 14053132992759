const Images = {
  logo: require("./images/logo.png"),
  sideBarLogo: require("./images/sidebar-logo.png"),
  login: require("./images/loginBg.png"),
  sidebarProPic: require("./images/sidebarProPic.png"),
  notificationProfile: require("./images/notification-profile.png"),
  expenseCardProgrammer: require("./images/expenseCardProgrammer.png"),
};

const Icons = {
  dashboard: require("./icons/sidebar/dashboard.png"),
  pay: require("./icons/sidebar/pay.png"),
  getPaid: require("./icons/sidebar/getPaid.png"),
  insights: require("./icons/sidebar/insights.png"),
  forecast: require("./icons/sidebar/forecast.png"),
  budget: require("./icons/sidebar/budget.png"),
  transactions: require("./icons/sidebar/transactions.png"),
  expenseCard: require("./icons/sidebar/expenseCard.png"),
  chat: require("./icons/sidebar/chat.png"),
  settings: require("./icons/sidebar/settings.png"),
  activeDashboard: require("./icons/sidebar/activeDashboard.png"),
  activePay: require("./icons/sidebar/activePay.png"),
  activeGetPaid: require("./icons/sidebar/activeGetPaid.png"),
  activeInsights: require("./icons/sidebar/activeInsights.png"),
  activeForecast: require("./icons/sidebar/activeForecast.png"),
  activeBudget: require("./icons/sidebar/activeBudget.png"),
  activeTransactions: require("./icons/sidebar/activeTransactions.png"),
  activeExpenseCard: require("./icons/sidebar/activeExpense.png"),
  headerDashboard: require("./icons/header/headerDashboard.png"),
  icici: require("./icons/header/icici.png"),
  hdfc: require("./icons/header/hdfc.png"),
  plus: require("./icons/header/plus.png"),
  email: require("./icons/header/email.png"),
  drive: require("./icons/header/drive.png"),
  dropbox: require("./icons/header/dropbox.png"),
  bell: require("./icons/header/bell.png"),
  headerProfile: require("./icons/header/headerProfile.png"),
  overDuePay: require("./icons/overDueReceive.png"),
  overDueReceive: require("./icons/overDuePay.png"),
  rightArrow: require("./icons/rightArrow.png"),
  dollar: require("./icons/dollar.png"),
  calendar: require("./icons/calendar.png"),
  call: require("./icons/call.png"),
  at: require("./icons/at.png"),
  letter: require("./icons/letter.png"),
  filter: require("./icons/filter.png"),
  sort: require("./icons/sort.png"),
  green: require("./icons/green.png"),
  red: require("./icons/red.png"),
  searchbar: require("./icons/searchbar.png"),
  editBtn: require("./icons/edit-btn.png"),
  zoho: require("./icons/zoho (1).png"),
  stripe: require("./icons/stripe.png"),
  saas: require("./icons/sage.png"),
  bin: require("./icons/bin.png"),
  close: require("./icons/close.png"),
  dollarBig: require("./icons/$.png"),
  leftArrow: require("./icons/leftArrow.png"),
  downArrow: require("./icons/downArrow.png"),
  emailPf: require("./icons/emailPf.png"),
  chatcircle: require("./icons/chatmodal/circle.png"),
  chatpf: require("./icons/chatmodal/chatpf.png"),
  closeicon: require("./icons/chatmodal/pluse.png"),
  chatarrowicon: require("./icons/chatmodal/chatarrow.png"),
  travelIcon: require("./icons/budgeting/travelcolor.png"),
  foodIcon: require("./icons/budgeting/foodcolor.png"),

  rentIcon: require("./icons/budgeting/rentcolor.png"),
  upArrow: require("./icons/upArrow.png"),
  revenueIcon: require("./icons/revenuesIcon.png"),
  interestIcon: require("./icons/interestIncome.png"),
  donationIcon: require("./icons/donations.png"),
  otherIcon: require("./icons/budgeting/othercolor.png"),
  voilet: require("./icons/violet.png"),
  lightpink: require("./icons/lightpink.png"),
  aqua: require("./icons/aqua.png"),
  rent: require("./icons/rent.png"),
  taxes: require("./icons/taxes.png"),
  internatiinal_bal: require("./icons/internal-balance.png"),
  other: require("./icons/other.png"),
  no_category: require("./icons/no-category.png"),
  close_btn: require("./icons/carbon_close.png"),
  redcanclebutton: require("./icons/redCancel.png"),
  redcircle: require("./icons/redCircle.png"),
  usd: require("./icons/usd.png"),
  Kotak: require("./icons/Kotak.png"),
  sbi: require("./icons/sbi.png"),
  mib: require("./icons/MIB.png"),
  pagination_left_arrow_blue: require("./icons/pagination_left_active.png"),
  pagination_right_arrow_blue: require("./icons/pagination_right_arrow.png"),
  pagination_left_arrow_black: require("./icons/pagination_left_arrow.png"),
  pagination_right_arrow_black: require("./icons/pagination_right_disable.png"),
};

const Lottie = {
  loader: require("./lottie/loader.json"),
};

const ExportedData = {
  Images,
  Icons,
  Lottie,
};

export default ExportedData;
