import React from "react";
import assets from "../../assets";
import "./styles.scss";

const ExpenseCard = () => {
  return (
    <div className="expenseCard">
      <div className="expenseCardContainer">
        <img src={assets.Images.expenseCardProgrammer} alt="programmer Image" />
        <h1>Coming Soon!</h1>
      </div>
    </div>
  );
};

export default ExpenseCard;
