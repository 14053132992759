// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addVendor {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
}
.addVendor .addVendorContainer {
  background-color: white;
  width: 35%;
}
.addVendor .addVendorContainer .addVendorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #cdcdcd;
  height: 100%;
  color: #111314;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 28px;
}
.addVendor .addVendorContainer .addVendorHeader img {
  height: 20px;
  width: 20px;
}
.addVendor .addVendorContainer form {
  padding: 0 20px;
  margin-top: 25px;
}
.addVendor .addVendorContainer form .formInfo {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #2d3436;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 28px;
}
.addVendor .addVendorContainer .addVendorButtons {
  padding: 30px 20px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

@media only screen and (max-width: 435px) {
  .addVendor {
    width: 100%;
  }
  .addVendor .addVendorContainer {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/addVendor/styles.scss"],"names":[],"mappings":"AAQA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,4BAAA;AAPF;AAQE;EACE,uBAAA;EACA,UAAA;AANJ;AAOI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,gCAAA;EACA,YAAA;EArBJ,cAsBwB;EArBxB,eAqBiC;EApBjC,oBAoBuC;EAnBvC,gBAmBgD;EAlBhD,iBAkBqD;AADvD;AAEM;EACE,YAAA;EACA,WAAA;AAAR;AAGI;EACE,eAAA;EACA,gBAAA;AADN;AAEM;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EAnCN,cAoC0B;EAnC1B,eAmCmC;EAlCnC,oBAkCyC;EAjCzC,gBAiCkD;EAhClD,iBAgCuD;AAIzD;AADI;EACE,kBAAA;EACA,aAAA;EACA,6BAAA;EACA,eAAA;AAGN;;AACA;EACE;IACE,WAAA;EAEF;EADE;IACE,WAAA;EAGJ;AACF","sourcesContent":["@mixin font-style($color, $size, $family, $weight, $lineHeight) {\n  color: $color;\n  font-size: $size;\n  font-family: $family;\n  font-weight: $weight;\n  line-height: $lineHeight;\n}\n\n.addVendor {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: whitesmoke;\n  .addVendorContainer {\n    background-color: white;\n    width: 35%;\n    .addVendorHeader {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      padding: 20px;\n      border-bottom: 1px solid #cdcdcd;\n      height: 100%;\n      @include font-style(#111314, 24px, Poppins, 600, 28px);\n      img {\n        height: 20px;\n        width: 20px;\n      }\n    }\n    form {\n      padding: 0 20px;\n      margin-top: 25px;\n      .formInfo {\n        margin-bottom: 15px;\n        display: flex;\n        flex-direction: column;\n        gap: 5px;\n        @include font-style(#2d3436, 14px, Poppins, 600, 28px);\n      }\n    }\n    .addVendorButtons {\n      padding: 30px 20px;\n      display: flex;\n      justify-content: space-evenly;\n      flex-wrap: wrap;\n    }\n  }\n}\n@media only screen and (max-width: 435px) {\n  .addVendor {\n    width: 100%;\n    .addVendorContainer {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
