import React from "react";
import './styles.scss'

const Page404 = () => {

    return(
        <div>

        </div>
    )
}

export default Page404;