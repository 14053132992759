// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/Poppins/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Lato/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/fonts/Roboto/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Poppins";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: "Lato";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
  font-family: "Roboto";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
.form-error {
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 400;
  color: #f90500 !important;
}`, "",{"version":3,"sources":["webpack://./src/theme/fonts.scss","webpack://./src/components/ErrorMessage/ErrorMessage.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,4CAAA;ACCF;ADIA;EACE,mBAAA;EACA,4CAAA;ACFF;ADIA;EACE,qBAAA;EACA,4CAAA;ACFF;AATA;EACE,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,yBAAA;AAWF","sourcesContent":["@font-face {\n  font-family: \"Poppins\";\n  src: url(\"../assets/fonts/Poppins/Poppins-Regular.ttf\");\n}\n\n\n\n@font-face {\n  font-family: \"Lato\";\n  src: url(\"../assets/fonts/Lato/Lato-Regular.ttf\");\n}\n@font-face {\n  font-family: \"Roboto\";\n  src: url(\"../assets/fonts/Roboto/Roboto-Regular.ttf\");\n}","@import \"../../theme/fonts.scss\";\n\n.form-error {\n  font-size: 10px;\n  font-family: \"Poppins\";\n  font-weight: 400;\n  color: #f90500 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
