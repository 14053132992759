import React from "react";
import "./styles.scss";
import "../inputField/styles.scss";
import FormErrorMessage from "../ErrorMessage";

const NormalSelect = ({
  name,
  options,
  value,
  onChange,
  error,
  messages,
  register,
  chartSelect,
  notification_dropdown,
}) => {
  const customStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "38px", // Adjust the height as needed
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: "auto", // Set the menu to appear at the bottom
    }),
  };
  return (
    <div className="select-container">
      <select
        name={name}
        style={customStyles}
        value={value}
        onChange={onChange}
        className={`${
          chartSelect
            ? "chart-select"
            : `${notification_dropdown}`
            ? "notification-dropdown"
            : "select-item"
        }`}
        {...register}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <div>
        <FormErrorMessage error={error} messages={messages} />
      </div>
    </div>
  );
};

export default NormalSelect;
