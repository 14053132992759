// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  margin-left: 25px;
  text-wrap: nowrap;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/screens/addEmploye/styles.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,iBAAA;EACD,mBAAA;EACC,aAAA;EACA,mBAAA;AACD","sourcesContent":[".menu {\n margin-left: 25px;\n text-wrap: nowrap;\nmargin-bottom: 10px;\n display: flex;\n flex-direction: row;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
