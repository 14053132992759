import React, { useState, useEffect } from "react";
import "./styles.scss";
import ExpenseCard from "../expenseCard";
import Budgeting from "../budgeting";
function AddEmploye() {
  const [activeMenu, setActiveMenu] = useState("Add Employee");
  const menus = [
    {
      id: 1,
      title: "Charts",
      link: "/main/budgeting",
    },
    {
      id: 1,
      title: "Add Employee",
      link: "/main/add-employe",
    },
  ];

  const handleMenuClick = (title, link) => {
    setActiveMenu(title);

    sessionStorage.setItem("activeMenu", title);
  };
  return (
    <>
      {activeMenu === "Add Employee" ? (
        <div>
          <div className="budgeting-title-container">
            <div className="menu">
              {menus.map(({ title, link }) => (
                <div
                  className={`budgeting-menu-item ${
                    activeMenu === title ? "budgeting-active" : ""
                  }`}
                  onClick={() => handleMenuClick(title, link)}
                >
                  {title}
                </div>
              ))}
            </div>
          </div>
          <ExpenseCard />
        </div>
      ) : (
        <Budgeting />
      )}
    </>
  );
}

export default AddEmploye;
