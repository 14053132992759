import axios from "axios";
import LoaderRef from "../components/loader";
import { Toast } from "../components/Toast";
import { getTokenFromStore } from "../utils";

export const request = ({ url, method, data, isLoader = true }) =>
  new Promise((resolve, reject) => {
    let token = getTokenFromStore();

    let config = {
      url: "https://pennyflo-backend-doodleblue-pennyfloapidoodbleblue.azuremicroservices.io/"  + url,
      method: method,
      data: data ? data : null,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "application/json",
      },
    };

    if (data == null) {
      delete config["data"];
    }
    showLoader(isLoader);

    axios(config)
      .then((res) => {
        showLoader(false);
        return resolve(res);
      })
      .catch(({ response }) => {
        showLoader(false);
        if (response) {
          let { status, data } = response;
          let { message } = data;
          Toast({ type: "error", message: message });
          if (status === 403) {
            window.location.href = "/auth/login";
          }
        } else {
        }

        return reject(response);
      });
  });

const showLoader = (status) => {
  if (LoaderRef && LoaderRef.render && LoaderRef.render.defaultProps) {
    LoaderRef.render.defaultProps.setLoaderStatus(status);
  }
};
