// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: "100vw";
  height: "100vh";
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.CloseButton {
  border-style: none;
  background-color: white;
  left: 90%;
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/components/loader/styles.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;EACA,cAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oCAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,uBAAA;EAEA,SAAA;EACA,kBAAA;AAAJ","sourcesContent":[".container-modal {\n    position: fixed;\n    top: 0px;\n    right: 0px;\n    bottom: 0px;\n    left: 0px;\n    width: \"100vw\";\n    height: \"100vh\";\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 999999;\n}\n\n.CloseButton {\n    border-style: none;\n    background-color: white;\n\n    left: 90%;\n    position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
